import axios from 'axios'
import { createPinia, } from 'pinia'
import { createApp, } from 'vue'
import Multiselect from 'vue-multiselect'

import bootstrap from '@/config/bootstrap.js'
import { DEFAULT_CURRENCY, MIN_DATA_DATE, } from '@/config/constants.js'
import helpers from '@/config/helpers.js'
import translations from '@/config/translations.js'

import useToggleStore from '@/state/toggleStore.js'

import buefyConfig from './config/buefy.js'
import useConfigStore from './state/configStore.js'
import AlertMessage from '@/components/layout/AlertMessage.vue'
import MainHeader from '@/components/layout/MainHeader.vue'
import SideDetails from '@/components/layout/SideDetails.vue'
import SideMenu from '@/components/layout/SideMenu.vue'
import Buefy from 'buefy'

import 'vue-multiselect/dist/vue-multiselect.esm.css'

// Bootstrap app

const { t, } = translations.I18N.global

import.meta.glob([
    '/public/fonts/**',
    '/public/webfonts/**',
    '/public/images/**'
])

// Initialize the Vue app
const app = createApp({
    data () {
        return {
            toggleStore: useToggleStore(), // give also access to the blade views (as dashboard.blade.php)
            defaultCurrency: DEFAULT_CURRENCY, // fallback, stats, ...
            defaultIdTag: 'C0FFEE67', // used in remote start
            wsBaseUrl: `${window.location.protocol === 'https:'
                ? 'wss'
                : 'ws'}://${window.location.hostname}/ws`, // ws://zest.freshmile.test/ws/supervision
            durationUnits () {
                return [
                    t('commons.durationUnits.days'),
                    t('commons.durationUnits.hours'),
                    t('commons.durationUnits.minutes'),
                    t('commons.durationUnits.seconds')
                ]
            },
            // wording for component WebsocketRefresh
            websocketRefreshWording: {
                autoRefreshDisabled: t('commons.autoRefreshDisabled'),
                autoRefreshOff: t('commons.autoRefreshOff'),
                autoRefreshOn: t('commons.autoRefreshOn'),
            },
            defaultDateFormat: 'YYYY-MM-DD HH:mm:ss',
            minDataDate: MIN_DATA_DATE,
            helpers,
        }
    },
    computed: {
        currentLocale () {
            return window.config.default_locale
        },
        /**
         * Locales list ready to insert to our "InputMultiLang" component
         * @returns {Array}
         */
        localesForMultiLang () {
            const allLocales = useConfigStore().config.locales
            const currentLocale = window.config.default_locale

            // If current locale is not the default language (first index), set it as first index for a better UX
            if (currentLocale !== allLocales[0]) {
                allLocales.splice(allLocales.indexOf(currentLocale), 1)
                allLocales.unshift(currentLocale)
            }

            return allLocales
        },
    },
})

// Access to helpers with this.$helpers or $helpers from the components
app.config.globalProperties.$helpers = helpers

app.use(translations.I18N)

// Init Buefy
// See https://buefy.github.io/#/documentation/constructor-options for all options
app.use(Buefy, buefyConfig)

// Register components globally
app.component('VueMultiselect', Multiselect)

// Create Pinia plugin
const pinia = createPinia()
app.use(pinia)

// Register pulp url
const pulp = axios.create({
    baseURL: import.meta.env.VITE_MOVE_BASE_URL,
    withCredentials: true,
})

app.config.globalProperties.$pulp = pulp

bootstrap()

// Layout components (import by default in each Vue)
app.component('MainHeader', MainHeader)
app.component('AlertMessage', AlertMessage)
app.component('SideMenu', SideMenu)
app.component('SideDetails', SideDetails)

// Export app and mount it on different files
export default app
