<template>
    <div
        id="activation-notifications"
        :class="{'has-notifications': totalActivationNotifications > 0}"
        class="navbar-item has-dropdown is-hoverable"
    >
        <a
            :class="{'has-text-light': totalActivationNotifications <= 0}"
            class="navbar-link"
            href="#"
        >
            <span class="tag is-rounded is-danger">{{ totalActivationNotifications }}</span>
            <span class="is-inline"> {{ $t('header.reports') }}</span>
        </a>
        <div
            v-if="(permissions.canReadReports || permissions.canReadMaintenance) && activationNotifications"
            class="navbar-dropdown"
        >
            <a
                v-if="permissions.canReadReports"
                class="navbar-item"
                :class="{ 'is-active': activeLink === resources.report }"
                :href="$helpers.setUrl('report')"
                data-testid="link-header-report"
            >
                <span
                    class="tag is-rounded mr-1"
                    :class="activationNotifications?.activations > 0 ? 'is-danger' : 'is-success'"
                >
                    {{ activationNotifications?.activations || 0 }}
                </span>
                <span>
                    {{ $t('header.reports') }}
                </span>
            </a>
            <a
                v-if="permissions.canReadMaintenance"
                class="navbar-item"
                :class="{ 'is-active': activeLink === resources.activationAlert }"
                :href="$helpers.setUrl('activationAlert', 'unknown-cbid')"
                data-testid="link-header-report"
            >
                <span
                    class="tag is-rounded mr-1"
                    :class="activationNotifications?.unknown_cbid > 0 ? 'is-danger' : 'is-success'"
                >
                    {{ activationNotifications?.unknown_cbid || 0 }}
                </span>
                <span>
                    {{ $t(`notifications.activation.unknown-cbid`) }}
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import { sumBy, } from 'lodash'
import { mapStores, } from 'pinia'

import $helpers from '@/config/helpers.js'
import { resources, } from '@/config/url.js'

import useConfigStore from '@/state/configStore.js'
import useUserStore from '@/state/userStore.js'

export default {
    computed: {
        $helpers () {
            return $helpers
        },
        resources () {
            return resources
        },
        ...mapStores(useUserStore, useConfigStore),
        permissions () {
            return this.userStore.permissions
        },
        activationNotifications () {
            return this.configStore.config.activations
        },
        totalActivationNotifications () {
            return sumBy(Object.values(this.activationNotifications)) || 0
        },
    },
}
</script>
