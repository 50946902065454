import { resources, Url, } from '@/config/url.js'

const apiUrls = {
    activationAlert: {
        unknownCbid: {
            batchFreeFromJail: () => `/${resources.activationAlert}/unknown-cbid/batch-free-from-jail`,
        },
    },
    dashboard: {
        getEvsesUsage: () => '/dashboard/evses-usage',
        getMonthlyMetrics: (type, year) => `/dashboard/monthly-metrics?type=${type}&year=${year}`,
    },
    device: {
        checkCbiAvailability: (cbi) => `/${resources.device}/availability?cbi=${cbi}`,
        multiUpdateMaintenance: () => `/${resources.device}/multi-update-is-seen`,
    },
    evse: {
        checkRefAvailability: (ref) => `/${resources.evse}/availability?ref=${ref}`,
        getOfflineByLocation: (locationId) => `/${resources.evse}/offlineByLocation?location=${locationId}`,
    },
    lan: {
        findByLazySearch: (query) => `/${resources.lan}/find?query=${query}`,
    },
    location: {
        findByLazySearch: (query) => `/${resources.location}/find?query=${query}`,
        getGeolocation: (locationRef) => `/${resources.location}/${locationRef}/geolocation`,
        getNames: () => `/${resources.location}/names`,
    },
    log: {
        getAudit: (resource = '', ref = '') => `/logs/audit/${resource}/${ref}`,
        getOcpi: (resource = '', ref = '') => `/logs/ocpi/${resource}/${ref}`,
        getBusiness: (resource = '', ref = '') => `/logs/business/${resource}/${ref}`,
        getOcpp: (cbi) => `/logs/ocpp/lanDevice/${cbi}`,
    },
    maintenanceAlert: {
        batchUpdateIsHandled: () => `/${resources.maintenanceAlert}/batch-update-is-handled`,
    },
    network: {
        /**
         * @param {string[]} refs - Array of locations refs
         * @returns {string}
         */
        countAllLocations: (refs) => {
            let queryString = '?'

            for (let i = 0; i < refs.length; i++)
                queryString += `refs[]=${refs[i]}&`

            return `/${resources.network}/locations-count${queryString}`
        },
        checkRefAvailability: (ref) => `/${resources.network}/availability?ref=${ref}`,
        findByLazySearch: (type, query) => `/${resources.network}/find?type=${type}&query=${query}`,
        getEvsesRefs: (networkRef) => `${resources.network}/${networkRef}/evses-ref`,
        getNames: () => `/${resources.network}/names`,
        hierarchyConfigurations: (ref) => `/${resources.network}/${ref}/hierarchyConfigurations`,
    },
    ocpp: {
        commands: {
            changeAvailability: (chargeboxid) => `/ocpp-command/${chargeboxid}/change-availability`,
            changeConfiguration: (chargeboxid) => `/ocpp-command/${chargeboxid}/change-configuration`,
            clearCache: (chargeboxid) => `/ocpp-command/${chargeboxid}/clear-cache`,
            getConfiguration: (chargeboxid) => `/ocpp-command/${chargeboxid}/get-configuration`,
            getDiagnostics: (chargeboxid) => `/ocpp-command/${chargeboxid}/get-diagnostics`,
            getDiagnosticsManual: (chargeboxid) => `/ocpp-command/${chargeboxid}/manual-get-diagnostics`,
            getDiagnosticsFile: (chargeboxid) => `/ocpp-command/${chargeboxid}/get-diagnostics-file`,
            ping: (chargeboxid) => `/ocpp-command/${chargeboxid}/ping`,
            reset: (chargeboxid) => `/ocpp-command/${chargeboxid}/reset`,
            updateFirmware: (chargeboxid) => `/ocpp-command/${chargeboxid}/update-firmware`,
            updateFirmwareManual: (chargeboxid) => `/ocpp-command/${chargeboxid}/manual-update-firmware`,
        },
        ercogener: {
            rearmingDevice: (chargeboxid) => `/ocpp-remote/ercogener/${chargeboxid}/rearming-device`,
            startOutlet: (chargeboxid) => `/ocpp-remote/ercogener/${chargeboxid}/start-outlet`,
            stopOutlet: (chargeboxid) => `/ocpp-remote/ercogener/${chargeboxid}/stop-outlet`,
        },
        remoteStart: (chargeboxid) => `/ocpp-remote/${chargeboxid}/remoteStart`,
        remoteStop: (chargeboxid) => `/ocpp-remote/${chargeboxid}/remoteStop`,
        remoteUnlock: (chargeboxid) => `/ocpp-remote/${chargeboxid}/remoteUnlock`,
    },
    session: {
        forceStop: (sessionRef) => `/${resources.session}/${sessionRef}/forceStop`,
    },
    stat: {
        getAll: (period, operatorRef = null) => {
            let url = `/${resources.stat}/allStats?period=${period}`
            if (operatorRef) url += `&id=${operatorRef}`

            return url
        },
        getHierarchy: () => `/${resources.stat}/operators`,
    },
    supervisor: {
        findByLazySearch: (query) => `/${resources.supervisor}/find?query=${query}`,
    },
    fix: {
        createIssue: () => `/${resources.fix}/${resources.issue}`,
        dispatchCreateNotification: (issueId) => `/${resources.fix}/${issueId}/dispatch/creation`,
        dispatchQuoteNotification: (issueId) => `/${resources.fix}/${issueId}/dispatch/quote`,
        findOpenIssuesByTitle: (query) => `/${resources.fix}/${resources.issue}/list?query=${query}&limit=10&is_closed=0&orderBy=created_at&ascending=0&byColumn=0`,
        getAssignedIssues: (userId) => `/${resources.fix}/${resources.issue}/list?is_closed=0&assigner_ids[]=${userId}&limit=0`,
        getStats: (period, operatorRef = null) => {
            let url = `/${resources.fixStats}/allFixStats?period=${period}`
            if (operatorRef) url += `&id=${operatorRef}`

            return url
        },
        getOperators: () => `/${resources.fixStats}/operators`,
        setModels: (issueId) => `/${resources.fix}/${resources.issue}/${issueId}/models`,
        setTags: (issueId) => `/${resources.fix}/${resources.issue}/${issueId}/tags`,
        setTasks: (issueId) => `/${resources.fix}/${resources.issue}/${issueId}/tasks`,
        setUsers: (issueId) => `/${resources.fix}/${resources.issue}/${issueId}/users`,
        uploadFiles: (issueId) => `/${resources.fix}/${resources.issue}/${issueId}/uploads`,
        issuesCount: () => new Url(resources.fix, 'count-issues'),
        issuesCountBy: (type, ref) => {
            const url = apiUrls.fix.issuesCount()

            // eslint-disable-next-line default-case
            switch (type) {
            case 'connector':
                url.byConnector(ref)
                break
            case 'network':
                url.byNetwork(ref)
                break
            case 'device':
                url.byDevice(ref)
                break
            case 'location':
                url.byLocation(ref)
                break
            case 'evse':
                url.byEvse(ref)
                break
            }

            return url
        },
    },
    contact: {
        search: (query) => `/${resources.contact}/search/${query}`,
        nameExists: (name) => `/${resources.contact}/name-exists/${name}`,
        sync: (type, ref) => `/${resources.contact}/sync/${type}/${ref}`,
    },
    contactGroups: {
        nameExists: (name) => `/${resources.contact}/groups/name-exists/${name}`,
        search: (query) => `/${resources.contact}/groups/search/${query}`,
        sync: (type, ref) => `/${resources.contact}/groups/sync/${type}/${ref}`,
    },
    tariff: {
        sharedMaxPrices: (tariffRef) => `/${resources.tariff}/${tariffRef}/shared-max-prices`,
        costPerPowerLevel: (tariffRef) => `/${resources.tariff}/${tariffRef}/costs-per-power-level`,
        find: (query) => `/${resources.tariff}/find?query=${query}`,
        updateNotification: (tariffRef) => `/${resources.tariff}/${tariffRef}/notification`,
        deleteNotification: (tariffRef) => `/${resources.tariff}/${tariffRef}/notification`,
        checkRefAvailability: (ref) => `/${resources.tariff}/availability?ref=${ref}`,
        getMinTimeBetweenNotification: (tariffRef) => `/${resources.tariff}/${tariffRef}/notification/min-time-between-notification`,
        getContainRestrictionPowerOrEnergy: (tariffRef) => `/${resources.tariff}/${tariffRef}/notification/contain-restriction-power-or-energy`,
        newPrice: (tariffRef) => `/${resources.tariff}/${tariffRef}/prices`,
        restrictions: (tariffRef) => `/${resources.tariff}/${tariffRef}/restrictions`,
        updateGroup: (tariffRef, groupId) => `/${resources.tariff}/${tariffRef}/group/${groupId}`,
        updatePrice: (tariffRef, priceId) => `/${resources.tariff}/${tariffRef}/prices/${priceId}`,
        globalRestrictions: (tariffRef) => `/${resources.tariff}/${tariffRef}/global-restrictions`,
    },
    retrocessionGroup: {
        findByLazySearch: (name) => `/${resources.retrocession}/find?query=${name}`,
        nameExists: (name) => `/${resources.retrocession}/name-exists/${name}`,
    },
    retrocessionLine: {
        show: (id) => `/${resources.retrocessionLine}/${id}`,
        create: () => `/${resources.retrocessionLine}`,
        update: (id) => `/${resources.retrocessionLine}/${id}`,
        delete: (id) => `/${resources.retrocessionLine}/${id}`,
    },
    tag: {
        search: (query) => `/${resources.tag}/search?q=${query}`,
    },
    agreement: {
        checkIfAlreadyExists: (cpoRef, emspRef) => `/${resources.network}/${cpoRef}/agreements/between/${emspRef}`,
    },
    operator: {
        // Both networks and providers
        getParents: (resource, ref) => `/${resource}/${ref}/parents`,
    },
    refund: {
        getZestRefunds: () => `/${resources.refund}/get-for-zest-sessions`,
    },
}

export default apiUrls
